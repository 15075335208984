var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"treasures-2-container__wrap"},[(_vm.lang === 'ru')?_c('div',{staticClass:"treasures-2-container"},[_vm._m(0),_vm._m(1),_vm._m(2),_vm._m(3),_vm._m(4),_vm._m(5)]):_c('div',{staticClass:"treasures-2-container treasures-2-container_en"},[_vm._m(6),_vm._m(7),_vm._m(8),_vm._m(9),_vm._m(10),_vm._m(11)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"treasures-2-item treasures-2-item_1"},[_c('div',{staticClass:"treasures-2-item__title"},[_vm._v("Росянка")]),_c('div',{staticClass:"treasures-2-item__descr"},[_c('p',[_vm._v(" Листья этого растения покрыты капельками клейкого сока. Садящиеся на них насекомые оказываются в ловушке: они прилипают и уже не могут улететь. После этого росянка закручивает лист, полностью обволакивая свою жертву, и в течение нескольких дней переваривает добычу. ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"treasures-2-item treasures-2-item_2"},[_c('div',{staticClass:"treasures-2-item__title"},[_vm._v("Живородящая ящерица")]),_c('div',{staticClass:"treasures-2-item__descr"},[_vm._v(" Благодаря своей устойчивости к низким температурам этот вид ящерицы может обитать даже в районе Полярного круга. Зимуют эти рептилии под землёй, забираясь в укрытия на глубину до 40 см. ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"treasures-2-item treasures-2-item_3"},[_c('div',{staticClass:"treasures-2-item__title"},[_vm._v("Калипсо луковичная")]),_c('div',{staticClass:"treasures-2-item__descr"},[_vm._v(" Один из 10 видов орхидей, произрастающих на территории заповедника. Своё название растение получило в честь нимфы из древнегреческой мифологии — Калипсо. ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"treasures-2-item treasures-2-item_4"},[_c('div',{staticClass:"treasures-2-item__title"},[_vm._v("Лебедь-кликун")]),_c('div',{staticClass:"treasures-2-item__descr"},[_vm._v(" В Лапландский заповедник эти птицы прилетают с мест зимовки в марте — начале апреля. Холода им не страшны — главное, чтобы была открытая вода, где лебеди могут кормиться. Эти птицы моногамны и образуют пару на всю жизнь. ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"treasures-2-item treasures-2-item_5"},[_c('div',{staticClass:"treasures-2-item__title"},[_vm._v("Обыкновенный серый сорокопут")]),_c('div',{staticClass:"treasures-2-item__descr"},[_vm._v(" Эта крупная певчая птица — безжалостный охотник. Основу её рациона составляют мышевидные грызуны, ящерицы и крупные насекомые. Пойманную добычу сорокопут может запасать впрок, нанизывая жертв на острые сучки и шипы. ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"treasures-2-item treasures-2-item_6"},[_c('div',{staticClass:"treasures-2-item__title"},[_vm._v("Дикий северный олень")]),_c('div',{staticClass:"treasures-2-item__descr"},[_vm._v(" Единственный вид оленей, у которого рога имеют как самцы, так и самки. В Лапландском заповеднике обитает примерно 1000 этих животных. Чтобы пережить суровые зимы, олени на время сбиваются в стада по 100 и более особей. ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"treasures-2-item treasures-2-item_1"},[_c('div',{staticClass:"treasures-2-item__title"},[_vm._v("Sundews")]),_c('div',{staticClass:"treasures-2-item__descr"},[_c('p',[_vm._v(" The leaves of this plant are topped with sticky secretions, which attract insects and entrap them with the mucilage, preventing their escape. After that, the leaves roll in to envelop and clog the prey, with the digestion process taking several days. ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"treasures-2-item treasures-2-item_2"},[_c('div',{staticClass:"treasures-2-item__title"},[_vm._v("Viviparous lizard")]),_c('div',{staticClass:"treasures-2-item__descr"},[_vm._v(" Thanks to its exceptional hardiness to the cold, this species of lizard can survive even near the Arctic Circle. They spend winters underground, hibernating in soil layers up to 40 cm deep. ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"treasures-2-item treasures-2-item_3"},[_c('div',{staticClass:"treasures-2-item__title"},[_vm._v("Calypso orchid")]),_c('div',{staticClass:"treasures-2-item__descr"},[_vm._v(" It is one of the ten species of orchid growing in the reserve. The plant takes its name from the nymph Calypso in Greek mythology. ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"treasures-2-item treasures-2-item_4"},[_c('div',{staticClass:"treasures-2-item__title"},[_vm._v("Whooper swan")]),_c('div',{staticClass:"treasures-2-item__descr"},[_vm._v(" These birds arrive in the Lapland Nature Reserve in March and early April from their wintering sites. Not afraid of the cold, they need open water to feed. Whooper swans form monogamous pairs and mate for life. ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"treasures-2-item treasures-2-item_5"},[_c('div',{staticClass:"treasures-2-item__title"},[_vm._v("Wild reindeer")]),_c('div',{staticClass:"treasures-2-item__descr"},[_vm._v(" This large songbird species is a ruthless hunter. Small rodents, lizards, and large insects are the bulk of its prey. Sometimes great grey shrikes can store their prey by impaling it upon sharp branches and thorns. ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"treasures-2-item treasures-2-item_6"},[_c('div',{staticClass:"treasures-2-item__title"},[_vm._v("Great grey shrike")]),_c('div',{staticClass:"treasures-2-item__descr"},[_vm._v(" This is the only species of reindeer in which both males and females grow antlers. The Lapland Nature Reserve is home to around 1,000 of these animals. To survive the harsh winters, reindeer form temporary herds of 100 or more animals. ")])])
}]

export { render, staticRenderFns }