var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"treasures-1-container__wrap"},[(_vm.lang === 'ru')?_c('div',{staticClass:"treasures-1-container"},[_vm._m(0),_vm._m(1),_vm._m(2),_vm._m(3),_vm._m(4),_vm._m(5)]):_c('div',{staticClass:"treasures-1-container"},[_vm._m(6),_vm._m(7),_vm._m(8),_vm._m(9),_vm._m(10),_vm._m(11)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"treasures-1-item treasures-1-item_1"},[_c('div',{staticClass:"treasures-1-item__title"},[_vm._v("Луток")]),_c('div',{staticClass:"treasures-1-item__descr"},[_c('p',{staticClass:"mb-10"},[_vm._v(" Эта утка — символ «Пасвика». Её изображение с 2021 года находится на логотипе заповедника. ")]),_c('p',[_vm._v(" В других частях России луток довольно редок, но в «Пасвике» это один из самых массовых и типичных видов. ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"treasures-1-item treasures-1-item_2"},[_c('div',{staticClass:"treasures-1-item__title"},[_vm._v("Жемчужница европейская")]),_c('div',{staticClass:"treasures-1-item__descr"},[_vm._v(" Рекордсмен-долгожитель среди пресноводных беспозвоночных: эти моллюски могут прожить свыше 100, а по некоторым данным, более 200 лет. ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"treasures-1-item treasures-1-item_3"},[_c('div',{staticClass:"treasures-1-item__title"},[_vm._v("Гапломитриум Хукера")]),_c('div',{staticClass:"treasures-1-item__descr"},[_vm._v(" В Мурманской области известно всего 10 местонахождений этого мха, причём каждое из них крайне малочисленное — не более 30 особей. ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"treasures-1-item treasures-1-item_4"},[_c('div',{staticClass:"treasures-1-item__title"},[_vm._v("Акулепейра лапландская")]),_c('div',{staticClass:"treasures-1-item__descr"},[_vm._v(" Паук-кругопряд, обитающий на территории Скандинавского полуострова и в Мурманской области. На территории заповедника «Пасвик» удалось встретить всего двух самок. ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"treasures-1-item treasures-1-item_5"},[_c('div',{staticClass:"treasures-1-item__title"},[_vm._v("Обыкновенная кутора")]),_c('div',{staticClass:"treasures-1-item__descr"},[_vm._v(" Эта крупная землеройка — отличный пловец, поэтому охотится не только на насекомых, но также на рыб и птенцов водоплавающих птиц. В её слюне содержится яд, обладающий парализующим действием. Благодаря этому кутора может делать запасы из живых, но обездвиженных животных. ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"treasures-1-item treasures-1-item_6"},[_c('div',{staticClass:"treasures-1-item__title"},[_vm._v("Бородатая неясыть")]),_c('div',{staticClass:"treasures-1-item__descr"},[_vm._v(" Один из самых крупных видов неясытей. В отличие от многих других сов активна не только по ночам, но и в светлое время суток. ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"treasures-1-item treasures-1-item_1"},[_c('div',{staticClass:"treasures-1-item__title"},[_vm._v("Smew")]),_c('div',{staticClass:"treasures-1-item__descr"},[_c('p',{staticClass:"mb-10"},[_vm._v(" This species of duck is a symbol of Pasvik and has been part of the nature reserve's logo since 2021. ")]),_c('p',[_vm._v(" While a relatively rare duck for other Russian regions, the smew is one of the most populous and common species in Pasvik. ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"treasures-1-item treasures-1-item_2"},[_c('div',{staticClass:"treasures-1-item__title"},[_vm._v("Freshwater pearl mussel")]),_c('div',{staticClass:"treasures-1-item__descr"},[_vm._v(" This is one of the longest-living invertebrates in existence: it can live more than 100 years and even more than 200, according to some data. ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"treasures-1-item treasures-1-item_3"},[_c('div',{staticClass:"treasures-1-item__title"},[_vm._v("Hooker's flapwort")]),_c('div',{staticClass:"treasures-1-item__descr"},[_vm._v(" The Murmansk Region is known to have only ten locations inhabited by this liverwort species, each of them with extremely low population numbers of no more than 30 plants. ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"treasures-1-item treasures-1-item_4"},[_c('div',{staticClass:"treasures-1-item__title"},[_vm._v("Aculepeira lapponica")]),_c('div',{staticClass:"treasures-1-item__descr"},[_vm._v(" This is the orb-weaving spider found on the Scandinavian Peninsula and in the Murmansk Region. Only two female spiders of this species have been spotted in the Pasvik nature reserve. ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"treasures-1-item treasures-1-item_5"},[_c('div',{staticClass:"treasures-1-item__title"},[_vm._v("Eurasian water shrew")]),_c('div',{staticClass:"treasures-1-item__descr"},[_vm._v(" This large shrew is a good swimmer that hunts for fish and waterfowl chicks in addition to insects. The Eurasian water shrew has venomous saliva with strong paralytic properties. This enables the shrew to move its paralysed prey to a store and keep it to eat later. ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"treasures-1-item treasures-1-item_6"},[_c('div',{staticClass:"treasures-1-item__title"},[_vm._v("Great grey owl")]),_c('div',{staticClass:"treasures-1-item__descr"},[_vm._v(" It is One of the largest species of wood owls. Unlike many other owls, great grey owls are active not only during the night but also in daytime. ")])])
}]

export { render, staticRenderFns }