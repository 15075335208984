var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"treasures-3-container__wrap"},[(_vm.lang === 'ru')?_c('div',{staticClass:"treasures-3-container"},[_vm._m(0),_vm._m(1),_vm._m(2),_vm._m(3),_vm._m(4),_vm._m(5),_vm._m(6)]):_c('div',{staticClass:"treasures-3-container treasures-3-container_en"},[_vm._m(7),_vm._m(8),_vm._m(9),_vm._m(10),_vm._m(11),_vm._m(12),_vm._m(13)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"treasures-3-item treasures-3-item_1"},[_c('div',{staticClass:"treasures-3-item__title"},[_vm._v("Овцебык")]),_c('div',{staticClass:"treasures-3-item__descr"},[_c('p',[_vm._v(" Когда‑то эти животные паслись по соседству с мамонтами. Пару тысяч лет назад изменение климата и охота привели к тому, что овцебыки исчезли с сибирских просторов. Вновь они появились здесь только в 1970-х годах — несколько десятков животных завезли из Канады. Сейчас на Таймыре обитает около 14 тыс. овцебыков, и на окраинах плато Путорана можно встретить их стада. ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"treasures-3-item treasures-3-item_2"},[_c('div',{staticClass:"treasures-3-item__title"},[_vm._v("Путоранский снежный баран")]),_c('div',{staticClass:"treasures-3-item__descr"},[_vm._v(" Уникальный эндемичный подвид снежного барана. Ареал его расположен на плато Путорана и более чем на тысячу километров удалён от районов распространения других подвидов снежного барана. Учёные не исключают, что путоранский подвид может быть реликтовым. Занесён в Красную книгу. ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"treasures-3-item treasures-3-item_3"},[_c('div',{staticClass:"treasures-3-item__title"},[_vm._v("Ястребинкa путоранская")]),_c('div',{staticClass:"treasures-3-item__descr"},[_vm._v(" Это растение с небольшими ярко-жёлтыми цветками встречается только на западе плато Путорана, в других местах пока не обнаружено. Занесена в Красную книгу Красноярского края. ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"treasures-3-item treasures-3-item_4"},[_c('div',{staticClass:"treasures-3-item__title"},[_vm._v("Сибирский углозуб")]),_c('div',{staticClass:"treasures-3-item__descr"},[_vm._v(" Единственный вид земноводных, способный обитать в зоне многолетней мерзлоты. Выживать во время холодов и долгих зим животному помогает значительное количество глицерина, вырабатываемого печенью. ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"treasures-3-item treasures-3-item_5"},[_c('div',{staticClass:"treasures-3-item__title"},[_vm._v("Сапсан")]),_c('div',{staticClass:"treasures-3-item__descr"},[_vm._v(" Этот сокол считается самой быстрой птицей на планете: во время пикирования он развивает скорость до 322 км/ч. Сапсан распространён практически на всех континентах (за исключением Антарктиды), однако численность его невысока — в России этот сокол внесён в Красную книгу. ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"treasures-3-item treasures-3-item_6"},[_c('div',{staticClass:"treasures-3-item__title"},[_vm._v("Пискулька")]),_c('div',{staticClass:"treasures-3-item__descr"},[_vm._v(" Своё название эта птица получила за характерный звук, издаваемый во время полёта. Занесена в Красную книгу России, всякая охота на неё запрещена. ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"treasures-3-item treasures-3-item_7"},[_c('div',{staticClass:"treasures-3-item__title"},[_vm._v("Муксун")]),_c('div',{staticClass:"treasures-3-item__descr"},[_vm._v(" В недавнем прошлом являлся одним из основных промысловых видов в бассейнах Енисея, Пясины, Таймыры и Хатанги. Однако в последние годы его численность стремительно сокращается. ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"treasures-3-item treasures-3-item_1"},[_c('div',{staticClass:"treasures-3-item__title"},[_vm._v("Muskox")]),_c('div',{staticClass:"treasures-3-item__descr"},[_c('p',[_vm._v(" A long time ago these animals grazed next to mammals. A couple of thousand years ago, climate change and hunting caused the muskox to disappear from the plains of Siberia. They reappeared here as late as the 1970s, when several dozen muskoxen were reintroduced from Canada. Today, Taimyr is home to around 14,000 muskoxen, with their herds seen on the outskirts of the Putoranа Plateau. ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"treasures-3-item treasures-3-item_2"},[_c('div',{staticClass:"treasures-3-item__title"},[_vm._v("Putorana snow sheep")]),_c('div',{staticClass:"treasures-3-item__descr"},[_vm._v(" This is a unique endemic subspecies of the bighorn sheep. The range of the Putoranа snow sheep is the Putoranа Plateau, which is more than a thousand kilometres away from where other subspecies of the snow sheep are found. Researchers believe that the Putorana subspecies might well be a relict one. It is listed in the Red Data Book. ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"treasures-3-item treasures-3-item_3"},[_c('div',{staticClass:"treasures-3-item__title"},[_vm._v("Hieracium putoranicum")]),_c('div',{staticClass:"treasures-3-item__descr"},[_vm._v(" This plant with small bright yellow flowers is found only in the west of the Putorana Plateau and has not yet been seen anywhere else. It is included in the Krasnoyarsk Territory’s Red Data Book. ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"treasures-3-item treasures-3-item_4"},[_c('div',{staticClass:"treasures-3-item__title"},[_vm._v("Siberian salamander")]),_c('div',{staticClass:"treasures-3-item__descr"},[_vm._v(" This is the only amphibian species capable of living in the permafrost area. To survive the cold weather and long winters, the salamander relies on high levels of glycerine produced by its liver. ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"treasures-3-item treasures-3-item_5"},[_c('div',{staticClass:"treasures-3-item__title"},[_vm._v("Peregrine falcon")]),_c('div',{staticClass:"treasures-3-item__descr"},[_vm._v(" This falcon is considered to be the fastest bird on the planet: during its high-speed dive, it can reach up to 322 km/h. The peregrine is common on almost all the continents except Antarctica, but its populations are fairly low, making it a red-listed species in Russia. ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"treasures-3-item treasures-3-item_6"},[_c('div',{staticClass:"treasures-3-item__title"},[_vm._v("Lesser white-fronted goose")]),_c('div',{staticClass:"treasures-3-item__descr"},[_vm._v(" This bird is known for its musical laugh-like honking call and is listed in the Red Data Book of Russia, with any hunting prohibited. ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"treasures-3-item treasures-3-item_7"},[_c('div',{staticClass:"treasures-3-item__title"},[_vm._v("Muksun")]),_c('div',{staticClass:"treasures-3-item__descr"},[_vm._v(" In the recent past, it was one of the main commercial fish species in the Yenisei, Pyasina, Taimyr and Khatanga basins. However, over the past few years, muksun populations have been rapidly declining. ")])])
}]

export { render, staticRenderFns }