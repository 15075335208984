var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"treasures-4-container__wrap"},[(_vm.lang === 'ru')?_c('div',{staticClass:"treasures-4-container"},[_vm._m(0),_vm._m(1),_vm._m(2),_vm._m(3),_vm._m(4),_vm._m(5)]):_c('div',{staticClass:"treasures-4-container treasures-4-container_en"},[_vm._m(6),_vm._m(7),_vm._m(8),_vm._m(9),_vm._m(10),_vm._m(11)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"treasures-4-item treasures-4-item_1"},[_c('div',{staticClass:"treasures-3-item__title"},[_vm._v("Красавка")]),_c('div',{staticClass:"treasures-3-item__descr"},[_vm._v(" Вид занесён в Красную книгу, а во всём мире, по подсчётам орнитологов, осталось не более 240 тыс. этих птиц. ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"treasures-4-item treasures-4-item_2"},[_c('div',{staticClass:"treasures-3-item__title"},[_vm._v("Мандаринка")]),_c('div',{staticClass:"treasures-3-item__descr"},[_vm._v(" Брачный наряд самца этой утки необычайно ярок и красочен: перья окрашены в жёлтый, оранжевый, синий, зелёный, чёрный и белый цвета. Однако в остальное время самцы выглядят гораздо скромнее и почти не отличаются от серо-коричневых самок. Не так давно мандаринки находились под угрозой исчезновения, но сейчас их численность постепенно восстанавливается. Занесена в Красную книгу. ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"treasures-4-item treasures-4-item_3"},[_c('div',{staticClass:"treasures-3-item__title"},[_vm._v("Колонок")]),_c('div',{staticClass:"treasures-3-item__descr"},[_vm._v(" Зверёк средних размеров, ведёт преимущественно ночной образ жизни. Колонки нередко поселяются поблизости от населённых пунктов, где охотятся на крыс и мышей, но также могут напасть на домашнюю птицу и кошек. Занесён в Красную книгу. ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"treasures-4-item treasures-4-item_4"},[_c('div',{staticClass:"treasures-3-item__title"},[_vm._v("Корсак")]),_c('div',{staticClass:"treasures-3-item__descr"},[_vm._v(" Корсаки похожи на обыкновенных лис, но заметно мельче их и более социальны — нередко несколько животных селятся вместе. Во время опасности животное притворяется мёртвым, но при первой же возможности убегает. Корсак занесён в Красную книгу в статусе «вызывающий наименьшие опасения». ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"treasures-4-item treasures-4-item_5"},[_c('div',{staticClass:"treasures-3-item__title"},[_vm._v("Кабарга")]),_c('div',{staticClass:"treasures-3-item__descr"},[_vm._v(" У этого небольшого оленя нет рогов, зато у самцов из-под верхней губы выступают длинные саблевидные клыки. Их животные используют в качестве турнирного оружия в битвах за самку. Известны случаи, когда во время таких поединков один из самцов кабарги смертельно ранил соперника. Животное занесено в Красную книгу как уязвимый вид. ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"treasures-4-item treasures-4-item_6"},[_c('div',{staticClass:"treasures-3-item__title"},[_vm._v("Росомаха")]),_c('div',{staticClass:"treasures-3-item__descr"},[_vm._v(" Этот представитель вида куньих достигает размеров средней собаки. Росомаха известна своей свирепостью и бесстрашием: она может дать отпор даже животным, значительно превосходящим её по размерам. Загнанная в угол способна напасть и на человека. Занесена в Красную книгу. ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"treasures-4-item treasures-4-item_1"},[_c('div',{staticClass:"treasures-3-item__title"},[_vm._v("Demoiselle crane")]),_c('div',{staticClass:"treasures-3-item__descr"},[_vm._v(" This species is listed in the Red Data Book, and ornithologists estimate that no more than 240,000 demoiselle cranes are currently in existence globally. ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"treasures-4-item treasures-4-item_2"},[_c('div',{staticClass:"treasures-3-item__title"},[_vm._v("Mandarin duck")]),_c('div',{staticClass:"treasures-3-item__descr"},[_vm._v(" Male mandarin ducks change to their colourful plumage during the mating season, with their feathers coloured yellow, orange, blue, green, and black and white. However, the rest of the time males keep a much lower profile and look almost identical to the grey and brown females. Not so long ago, mandarin ducks were endangered, but right now their populations are gradually recovering. It is listed in the Red Data Book. ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"treasures-4-item treasures-4-item_3"},[_c('div',{staticClass:"treasures-3-item__title"},[_vm._v("Siberian weasel")]),_c('div',{staticClass:"treasures-3-item__descr"},[_vm._v(" A medium-sized and a predominantly nocturnal creature. Siberian weasels often live not far from human settlements, where they hunt rats and mice and sometimes can also attack poultry and cats. The species is listed in the Red Data Book. ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"treasures-4-item treasures-4-item_4"},[_c('div',{staticClass:"treasures-3-item__title"},[_vm._v("Corsac fox")]),_c('div',{staticClass:"treasures-3-item__descr"},[_vm._v(" Corsac foxes look like typical foxes but are noticeably smaller and more social, with several animals often living together. In times of danger, corsacs feign death – only to escape at their first chance. Corsacs are listed in the Red Data Book as a species of least-concern. ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"treasures-4-item treasures-4-item_5"},[_c('div',{staticClass:"treasures-3-item__title"},[_vm._v("Siberian musk deer")]),_c('div',{staticClass:"treasures-3-item__descr"},[_vm._v(" These medium-sized deer have no antlers but have long tusks that project downward from their mouth, which they use to compete with other males for females. There are known cases of male dusk deer fatally wounding their opponents this way. The animal is included in the Red Data Book as a vulnerable species. ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"treasures-4-item treasures-4-item_6"},[_c('div',{staticClass:"treasures-3-item__title"},[_vm._v("Wolverine")]),_c('div',{staticClass:"treasures-3-item__descr"},[_vm._v(" This member of the marten species reaches the size of an average dog. Wolverines are known for their great ferocity and strength out of proportion to their size, with relative attack even humans when desperate. The wolverine is red-listed. ")])])
}]

export { render, staticRenderFns }