<template>
    <main class="main">
        <slot></slot>
    </main>
</template>

<script>
export default {
    components: {
        
    }
}
</script>

<style lang="scss">
.main {
    padding: 3.75rem 0 2.5rem 0;
}
</style>